import { Amplify, Auth } from 'aws-amplify';
import { StateService } from '@uirouter/core';
import rewritehandoff, { RewriteHandoffService } from '../rewritehandoff/rewritehandoff.service';
import uiRouter from '@uirouter/angularjs';

const oauth = {
    // Domain name
    domain: process.env.COGNITO_SERVER_URL,

    // Authorized scopes
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],

    // Callback URL
    redirectSignIn: `${window.location.origin}/login`,

    // Sign out URL
    redirectSignOut: `${window.location.origin}/login`,

    // 'code' for Authorization code grant,
    // 'token' for Implicit grant
    responseType: 'code',
};

export class CognitoService {
    signingIn = false;
    accessToken = null;
    constructor(
        private $http: any, // Custom get config
        private $location: ng.ILocationService,
        private $log: ng.ILogService,
        private $window: ng.IWindowService,
        private $state: StateService,
        private $rootScope: ng.IRootScopeService,
        private rewritehandoff: RewriteHandoffService,
    ) {
        Amplify.configure({
            Auth: {
                region: process.env.AWS_REGION,
                userPoolId: process.env.COGNITO_POOL_ID,
                userPoolWebClientId: process.env.COGNITO_POOL_APP_CLIENT_ID,
                mandatorySignIn: true,
                oauth: oauth,
            },
        });
    }
    async handleRedirect() {
        console.log('redirecturl: ', `${window.location.origin}/login`);
        try {
            const session = await Auth.currentSession();
            this.accessToken = await session.getAccessToken().getJwtToken();
        } catch (error) {
            console.log(error);
        }

        if (this.accessToken !== null && process.env.AUTH_PROVIDER === 'COGNITO') {
            await this.signIn();
            return true;
        }
        return false;
    }
    async signIn() {
        if (!this.accessToken) {
            Auth.federatedSignIn();
            return;
        }

        try {
            const data = await this.$http.post(
                `${process.env.API_URL}/user/authenticate`,
                {
                    data: {
                        type: 'authenticate',
                        attributes: {
                            access_token: this.accessToken,
                            provider: 'okta',
                        },
                    },
                },
                {
                    headers: {
                        Accept: 'application/vnd.api+json',
                        'Content-Type': 'application/vnd.api+json',
                    },
                    skipAuthorization: true,
                },
            );
            this.$window.localStorage.setItem('token', data.data.data.attributes.json_web_token);
            const redirect = angular.copy(this.$window.localStorage.getItem('redirect'));
            const params = angular.copy(JSON.parse(this.$window.localStorage.getItem('params')) || {});
            this.$window.localStorage.removeItem('redirect');
            this.$window.localStorage.removeItem('params');
            if (redirect) {
                this.$location.search(params);
                this.$location.path(redirect);
            } else {
                this.$state.go('home', params, { reload: true });
            }
            this.$rootScope.$apply();
        } catch (err) {
            await this.signOut();
            this.$state.go('logout');
            this.$rootScope.$apply();
            throw err;
        }
    }
    async signOut() {
        await Auth.signOut();
        if (this.rewritehandoff.hasLoggedintoRewriteApp()) {
            this.$window.location.href = `${this.rewritehandoff.newOrigin}/logout`;
        }
        return true;
    }
}

export default angular.module('mpdx.services.cognito', [uiRouter, rewritehandoff]).service('cognito', CognitoService)
    .name;
